<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        A bicycle pump inflates a tire whose volume is
        <number-value :value="volumeTire" unit="\text{mL}" />
        until its internal pressure is
        <number-value :value="pressureTire" unit="\text{bar}" />
        at a temperature of
        <number-value :value="temp" unit="^\circ\text{C.}" />
      </p>

      <p class="mb-2">a) How many moles of air is contained in the tire?</p>

      <calculation-input
        v-model="inputs.molAir"
        class="mb-5"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) What volume of air at 1.00 atm and
        <number-value :value="temp" unit="^\circ\text{C}" />
        did the pump transfer?
      </p>
      <calculation-input
        v-model="inputs.volumeAir"
        class="mb-0"
        prepend-text="$\text{V:}$"
        append-text="$\text{L}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question450',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molAir: null,
        volumeAir: null,
      },
    };
  },
  computed: {
    volumeTire() {
      return this.taskState.getValueBySymbol('volumeTire').content;
    },
    pressureTire() {
      return this.taskState.getValueBySymbol('pressureTire').content;
    },
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
  },
};
</script>
